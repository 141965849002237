import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { CommonService } from '../../core/common.service';
import { AuthService } from '../../core/authentication/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DefaultService } from '../../containers/default-layout/default.service';
import { AppService } from 'app/_services/app.service';

@Component({
  selector: 'app-switch-role',
  templateUrl: './switch-role.component.html',
  styleUrls: ['./switch-role.component.css']
})
export class SwitchRoleComponent implements OnInit {

  activityList: [];
  switchRoleId = "";
  redirectUrl = "";
  submitted = false;
  buttonCheck = localStorage.getItem('switchRoleId');
  browserId = localStorage.getItem('uniqueId');
  ad_token = localStorage.getItem('ad-token');
  userId = "";
  email = "";
  load = true;
  imageVal = "";


  switchRoleForm: UntypedFormGroup = new UntypedFormGroup({
    'role': new UntypedFormControl("")
  })

  constructor(private formBuilder: UntypedFormBuilder,
    private authService: AuthService, private cmnService: CommonService,
    private router: Router, private defaultService: DefaultService,
    private actRoute: ActivatedRoute, private appService: AppService) {
    this.actRoute.params.subscribe(
      (params: Params) => {
        this.email = params['user'];
        localStorage.setItem('switchroleUrl', this.email);
      });

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.defaultService.profileImage(this.userId);
    }, 1000);
    // this.defaultService.profileImage();
    this.initialLoad();
  }
  length;
  initialLoad() {
      // this.userId = localStorage.getItem('user_id');
      // this.cmnService.getAllRoles({ user_id: this.userId })
      // .subscribe((response) => {
      //   this.activityList = response.message;
      //   this.length = this.activityList.length;
      //   this.load = false;
      // })

    this.cmnService.userLogin({ email: this.email, ad_token: this.ad_token, browser_id: this.browserId })
      .subscribe((response) => {
        if (response.status === 2) {
          localStorage.setItem('user_id', response.info['user_id']);
          localStorage.setItem('username', response.info['username']);
          localStorage.setItem('token', response.info['token']);
          localStorage.setItem('loggedIn', '1');
          localStorage.setItem('groupname', response.info['groupname']);
          localStorage.setItem('groupid', response.info['groupid']);
          localStorage.setItem('sectorname', response.info['sectorname']);
          localStorage.setItem('sectorid', response.info['sectorid']);
          localStorage.setItem('roleId', response.info['role_id']);
          this.appService.setUserLoggedIn(true)
          this.userId = localStorage.getItem('user_id');
          this.rolesDisplay(response.info['user_id'])
        } else {
          alert(`${response.message}`);
          this.submitted = false;
          this.load = true;
        }
      })
  }
  rolesDisplay(userId) {
    this.cmnService.getAllRoles({ user_id: userId })
      .subscribe((response) => {
        this.activityList = response.message;
        this.length = this.activityList.length;
        this.load = false;
      })
  }

  getData(id, name, redirect_link) {
    this.switchRoleId = id;
    this.redirectUrl = redirect_link;
    localStorage.setItem('switcholeName', name);
    localStorage.setItem('displaySwitchRole', 'true');
  }
  onSwitchrole() {
    localStorage.setItem('displayNavigation', 'true');
    var url = localStorage.getItem('navigateUrl')
    if(url){
      this.redirectUrl = url;
    }
    if (this.switchRoleId == "" && this.buttonCheck == null) {
      this.submitted = true;
    }
    else if (this.switchRoleId == "") {
      localStorage.setItem('switchRoleId', this.buttonCheck);
      localStorage.removeItem('navigateUrl');      
      window.location.href = this.redirectUrl;
    } else {
      localStorage.setItem('switchRoleId', this.switchRoleId);
      localStorage.removeItem('navigateUrl');  
      window.location.href = this.redirectUrl;
    }
  }


  // onSwitchrole(){
  //   if(this.switchRoleId==""){
  //     localStorage.setItem('switchRoleId',this.buttonCheck);
  //   }else{
  //     localStorage.setItem('switchRoleId',this.switchRoleId);
  //   }
  //   window.location.href = '/';
  // }

  logout() {
    this.authService.logout();
  }

}
