import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { LogoutComponent } from './views/logout/logout.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuardsService } from './core/auth-guards.service';
import { InterceptorService } from './core/authentication/interceptor.service';
import { SwitchRoleComponent } from './views/switch-role/switch-role.component';
import { ProfileComponent } from './views/profile/profile.component';
import { NewloginComponent } from './views/newlogin/newlogin.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: NewloginComponent,
    //component:LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  // {
  //   path: 'switch-role',
  //   component: SwitchRoleComponent,
  //   data: {
  //     title: 'Switch Activity'
  //   }
  // },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      title: 'Logout Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    // data: {
    //   title: 'Home'
    // },
    children: [
        {
    //path: 'switch-role',
    path: 'switch-role/:user',
    component: SwitchRoleComponent,
    // data: {
    //   title: 'Switch Activity'
    // }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {
      title: 'Profile'
    }
  },
      {
        path: 'program',
        loadChildren: () => import('./views/program/program.module').then(m => m.ProgramModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'teams',
        loadChildren: () => import('./views/program/eoi/teams/teams.module').then(m => m.TeamsModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'proposal',
        loadChildren: () => import('./views/program/proposal/proposal.module').then(m => m.ProposalModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'project',
        loadChildren: () => import('./views/program/project/project.module').then(m => m.ProjectModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'organisation',
        loadChildren: () => import('./views/program/organisation-data/organisation-data.module').then(m => m.OrganisationDataModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'engagement',
        loadChildren: () => import('./views/program/engagement/engagement.module').then(m => m.EngagementModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'google-charts',
        loadChildren: () => import('./views/googlecharts/googlecharts.module').then(m => m.GooglechartsModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'activity',
        loadChildren: () => import('./views/program/eoi/activity/activity.module').then(m => m.ActivityModule)
     ,canActivate: [AuthGuardsService]
      },
      {
        path: 'activity-task',
        loadChildren: () => import('./views/program/eoi/task/task.module').then(m => m.TaskModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'task',
        loadChildren: () => import('./views/program/eoi/main-task/main-task.module').then(m => m.MainTaskModule)
        ,canActivate: [AuthGuardsService]
      },
      
      {
        path: 'milestone',
        loadChildren: () => import('./views/program/eoi/milestone/milestone.module').then(m => m.MilestoneModule)
     ,canActivate: [AuthGuardsService]
      },
      {
        path: 'document',
        loadChildren: () => import('./views/program/eoi/document/document.module').then(m => m.DocumentModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'meeting',
        loadChildren: () => import('./views/program/eoi/meeting/meeting.module').then(m => m.MeetingModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'matListing',
        loadChildren: () => import('./views/program/eoi/project-mat-listing/matListing.module').then(m => m.MatListingModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'funder',
        loadChildren: () => import('./views/program/funder/funder.module').then(m => m.FunderModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'funderEngagement',
        loadChildren: () => import('./views/program/eoi-funder/eoi-funder.module').then(m => m.EoiFunderModule)
        ,canActivate: [AuthGuardsService]
      },
      {
        path: 'report',
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule)
        ,canActivate: [AuthGuardsService]
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  // imports: [ RouterModule.forRoot(routes) ],
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload'
    })
  ],
  exports: [ RouterModule ,FormsModule, ReactiveFormsModule],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
    }
    ],
})
export class AppRoutingModule {}
